<script lang="jsx">
export default {
  name: 'BHeaderCartSvgFree',
  functional: true,
  render() {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70418 1.95992V0.5H0.505127V2.82253V5.64669V7.72576H1.38061C1.61999 6.88755 2.39173 6.27393 3.30679 6.27393C4.22185 6.27393 4.99359 6.88755 5.23297 7.72576H5.80847C6.04786 6.88755 6.81959 6.27393 7.73465 6.27393C8.6497 6.27393 9.42144 6.88754 9.66083 7.72574H10.2849V2.8036L7.70418 1.95992ZM7.70418 4.72558V3.01201L9.28455 3.52858V4.72558H7.70418Z"
        fill="white"
      />
      <circle
        cx="7.7583"
        cy="8.27686"
        r="1"
        fill="white" />
      <circle
        cx="3.30688"
        cy="8.27686"
        r="1"
        fill="white" />
    </svg>
  },
}
</script>
