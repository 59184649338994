<script lang="jsx">
export default {
  name: 'BHeaderCartSvgSave',
  functional: true,
  render() {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M1.08385 3.82435L3.89191 6.6324L6.1298 4.39451L10.1931 8.45767"
        stroke="white"
        stroke-width="1.5"
      />
      <path
        d="M10.5219 4.59961L10.5219 9.00049L6.12103 9.00049"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  },
}
</script>
