import { inject } from 'vue'
import type {
  CommonHeaderContext,
  CommonHeaderContextProvide,
  CommonHeaderEventProxyProvide,
  C_CommonHeader,
} from '../../types'

export function injectCommonHeaderContext() {
  const commonHeaderContext =
    inject<CommonHeaderContext>(
      'COMMON_HEADER_CONTEXT_PROVIDE' as CommonHeaderContextProvide,
    ) || null

  return commonHeaderContext
}

export function injectCommonHeaderEventProxy() {
  const commonHeaderEventProxy =
    inject<C_CommonHeader.EventProxy>(
      'COMMON_HEADER_EVENT_PROXY_PROVIDE' as CommonHeaderEventProxyProvide,
    ) || null
  return commonHeaderEventProxy
}
