<template>
  <a
    ref="refBHeaderCart"
    :class="headerCartClass"
    :aria-label="ariaLabel"
    @click="handleClick"
  >
    <CartSvg />

    <span
      v-show="formatNum"
      class="bsc-cart-num"
    >
      {{ formatNum }}
    </span>

    <transition
      :name="transitionName"
      @after-enter="afterEnter"
    >
      <span
        v-if="type === 'freeshipping'"
        key="freeshipping"
        class="bsc-cart-tag bsc-cart-tag--freeshipping"
        :style="bgStyle"
      >
        <FreeshippingSvg />
        <span class="bsc-cart-tag__text">Free</span>
      </span>
      <span
        v-else-if="type === 'save'"
        key="save"
        :class="[
          'bsc-cart-tag',
          'bsc-cart-tag--save',
          {
            'no-padding': text !== 'SAVE',
          },
        ]"
        :style="bgStyle"
      >
        <template v-if="!textStyle && text === 'SAVE'">
          <SaveSvg />
        </template>
        <span
          class="bsc-cart-tag__text"
          :style="textStyle"
        >
          {{ text }}
        </span>
      </span>
      <span
        v-else-if="type === 'gift'"
        key="gift"
        class="bsc-cart-tag bsc-cart-tag--gift"
        :style="bgStyle"
      >
        <template v-if="!textStyle">
          <GiftSvg />
        </template>
        <span
          class="bsc-cart-tag__text"
          :style="textStyle"
        >
          GIFT
        </span>
      </span>
    </transition>
  </a>
</template>

<script name="BHeaderCart" setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted, nextTick } from 'vue'
import {
  cartTagTip,
  registerCart,
  unregisterCart,
  useCartNum,
} from '@shein-aidc/bs-sdk-cart-tag-tip'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useAnalysis } from '../../common/analysisSource'
import { injectCommonHeaderEventProxy } from '@shein-aidc/bs-common-header-mobile-vue2'
import { useIsVisible } from './hooks/useIsVisible'
import CartSvg from './svgs/cart.vue'
import FreeshippingSvg from './svgs/freeshipping.vue'
import SaveSvg from './svgs/save.vue'
import GiftSvg from './svgs/gift.vue'

import type { TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import type { AS_HeaderCart, C_HeaderCart, Placement } from '../../types'

const emit = defineEmits(['before-click'])

interface IProps {
  analysisSource?: AS_HeaderCart.AnalysisSource
  themeVars?: C_HeaderCart.ThemeVars
  ariaLabel?: string
  placement?: Placement
  offsetY?: number
  loc?: string
}
const props = withDefaults(defineProps<IProps>(), {
  placement: 'bottom-end',
  offsetY: 10,
})

const { triggerNotice } = useAnalysis(props.analysisSource)

const CommonHeaderEventProxyEventProxy = injectCommonHeaderEventProxy()

if (CommonHeaderEventProxyEventProxy) {
  CommonHeaderEventProxyEventProxy.describe('BHeaderCart')
  CommonHeaderEventProxyEventProxy.subscribe('BHeaderCart', {
    pageOnload() {
      onExpose()
    },
  })
}

const appConfigs = useAppConfigs()

const { cartNum } = useCartNum()
const formatNum = computed(() => {
  if (cartNum.value > 99) {
    return '99+'
  }
  return cartNum.value
})

const headerCartClass = computed(() => {
  const clas:string[] = ['bsc-header-cart']
  let sizeClassname = 'bsc-header-cart__24px'
  if (props.themeVars?.iconSize && [24, 32].includes(props.themeVars.iconSize)) {
    sizeClassname = `bsc-header-cart__${props.themeVars.iconSize}px`
  }
  clas.push(sizeClassname)
  return clas
})
const ariaLabel = computed(() => props.ariaLabel || appConfigs.$language.Cart || 'Cart')

const handleClick = () => {
  emit('before-click')
  cartTagTip.getExposeData().then(extraData => {
    triggerNotice({
      id: 'click_top_site_bag.comp_header-cart',
      data: {
        type: '1',
        abtest: '',
        actual_point: extraData.actual_point,
        available_point: extraData.available_point,
        bag_goods_count: cartNum.value,
        loc: props.loc,
      },
    })
    appConfigs.$router.push({ path: `${appConfigs.$envs.langPath}/cart` })
  })
}
const handleClickTip = ({ extraData }) => {
  triggerNotice({
    id: 'click_equity_pop.comp_header-cart',
    data: {
      actual_point: extraData.actual_point_tip,
      available_point: extraData.available_point_tip,
    },
  })
}
const handleExposeTip = ({ extraData }) => {
  triggerNotice({
    id: 'expose_equity_pop.comp_header-cart',
    data: {
      actual_point: extraData.actual_point_tip,
      available_point: extraData.available_point_tip,
    },
  })
}
const onExpose = () => {
  setTimeout(() => {
    if (!isVisible.value) return
    cartTagTip.getExposeData().then(extraData => {
      triggerNotice({
        id: 'expose_home_bag.comp_header-cart',
        data: {
          actual_point: extraData.actual_point,
          available_point: extraData.available_point,
          bag_goods_count: cartNum.value,
          loc: props.loc,
        },
      })
    })
  }, 300)
}

const refBHeaderCart = ref<HTMLElement | null>(null)
const { isVisible } = useIsVisible(refBHeaderCart)
const cartListener = {
  showtime: (steps, config) => {
    setTag(steps, {
      reference: refBHeaderCart.value,
      placement: props.placement,
      offsetY: props.offsetY,
      ...config,
    })
  },
  endtime: () => {
    clear()
  },
}
watch(
  isVisible,
  v => {
    if (typeof window === 'undefined') return
    if (v) {
      registerCart('header-cart', cartListener)
      cartTagInit()
    } else {
      unregisterCart('header-cart', cartListener)
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  // watch isVisible 注册
  // registerCart('header-cart', cartListener)
})
onUnmounted(() => {
  unregisterCart('header-cart', cartListener)
  CommonHeaderEventProxyEventProxy?.describe('BHeaderCart')
})


// cart-tag
import { createCartTip } from './components/cart-tip/index.ts'

const bgStyle = computed(() => {
  if (props.themeVars?.tagTipBgColor) {
    return {
      '--tag-tip-bg-color': props.themeVars.tagTipBgColor,
    }
  }
  return undefined
})
const textStyle = computed(() => {
  if (props.themeVars?.tagTipTextColor) {
    return {
      '--tag-tip-text-color': props.themeVars.tagTipTextColor,
    }
  }
  return undefined
})

const transitionName = ref('')
const type = ref('')
const text = ref<boolean | string | null>('')

let excludeScroll = false
let nextStep: TagTip.Step | null = null
let timer: ReturnType<typeof setTimeout> | null = null
let callback: TagTip.Config['callback']

const setTag = async (steps: TagTip.Step[], config: TagTip.Config) => {
  const [step1 = null, step2 = null] = steps
  // TODO: step1.excludeScroll 放在 config 中
  if (step1?.excludeScroll) await sleep(300)
  excludeScroll = false

  clear()

  nextTick(() => {
    if (step1) {
      excludeScroll = step1.excludeScroll || false
      if (!step1.type) {
        callback = config.callback
        type.value = ''
        text.value = ''
        transitionName.value = ''

        // 没有动画 手动执行 afterEnter
        afterEnter()
      } else {
        if (step1.noTips || !step1.tips) {
          callback = config.callback
          // 没有动画 手动执行 afterEnter
          if (type.value === step1.type) afterEnter()
        } else {
          nextTick(() => {
            const cfg: C_HeaderCart.TipOpenConfig = {
              bgStyle: bgStyle.value,
              textStyle: textStyle.value,
              reference: config.reference,
              placement: config.placement,
              offsetY: config.offsetY,
            }
            if (!step2) cfg.callback = config.callback

            refHeaderCartTip.open(step1, cfg)
          })
        }
        type.value = step1.type
        text.value = step1.tag
        nextStep = null
        transitionName.value = 'bsc-header-cart__fade'
      }
    }

    if (step2) {
      nextStep = step2
      timer = setTimeout(() => {
        callback = config.callback
        type.value = step2.type
        text.value = step2.tag
        transitionName.value = 'bsc-header-cart__zoom'
      }, 3000)
    }
  })
}

const afterEnter = () => {
  callback?.()
  callback = undefined
}

// 打断动画
const clear = () => {
  if (excludeScroll) return
  transitionName.value = ''

  if (nextStep) {
    type.value = nextStep.type
    text.value = nextStep.tag
    nextStep = null
    clearTimeout()
  }

  refHeaderCartTip.clear()

  // 动画被打断不会执行 afterEnter
  afterEnter()
}

const clearTimeout = () => {
  if (timer) {
    window.clearTimeout(timer)
    timer = null
  }
}

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const cartTagInit = async () => {
  const data = await cartTagTip.getCurrentTag()
  type.value = data.type
  text.value = data.tag
}

let refHeaderCartTip

onMounted(() => {
  cartTagInit()

  refHeaderCartTip = createCartTip()

  refHeaderCartTip.$on('expose', () => {
    cartTagTip.getExposeData().then(extraData => {
      handleExposeTip({ extraData })
    })
  })
  refHeaderCartTip.$on('click', () => {
    cartTagTip.getExposeData().then(extraData => {
      handleClickTip({ extraData })
    })
  })
})

onUnmounted(() => {
  refHeaderCartTip.$destroy()
})


defineExpose({
  setTag,
  clear,
  onExpose,
})
</script>

<style lang="less">
.bsc-header-cart {
  position: relative;
  line-height: initial;
  font-size: 0;

  &__32px {
    .bsc-header-cart__svg {
      width: 32px;
      height: 32px;
    }

    .bsc-cart-num {
      top: -7px;
    }

    .bsc-cart-tag {
      bottom: -9px;
    }
  }
}

.bsc-cart-num {
  position: absolute;
  top: -9px;
  right: -7.5px /* rtl: auto */;
  left: auto /* rtl: -7.5px */;
  min-width: 15px;
  height: 15px;
  padding: 3.75px;
  box-sizing: border-box;
  font-size: 10px;
  display: block;
  border-radius: 15px;
  text-align: center;
  line-height: 5.5px;
  background: #fe3b30;
  color: #fff;
  border: 1px solid var(--shein-common-header-badge-border-color, transparent);
}

.bsc-cart-tag {
  position: absolute;
  left: 50%;
  bottom: -10px;
  display: inline-flex;
  align-items: center;
  padding: 0 2px;
  height: 14px;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 8px;
  transform: translateX(-50%);

  &.no-padding {
    padding: 0;
  }

  &--freeshipping,
  &--gift,
  &--save {
    color: #fff;
  }

  &--freeshipping {
    padding: 0 2px 0 4px;
    background: #198055;
  }

  &--gift {
    padding: 0 2px 0 4px;
  }

  &--save,
  &--gift {
    background-color: #fa6338;
    background-color: var(--tag-tip-bg-color, #fa6338);
  }

  &__text {
    margin: 0 -2px;
    transform: scale(0.75);
    color: var(--tag-tip-text-color, #fff);
  }
}

@keyframes bsc-header-cart__fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bsc-header-cart__zoom {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateX(-50%) scaleX(0.5);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) scaleX(1);
  }
}

.bsc-header-cart__zoom-enter-active {
  animation: bsc-header-cart__zoom 0.3s;
}

.bsc-header-cart__zoom-leave-active {
  animation: bsc-header-cart__zoom 0.3s reverse;
}

.bsc-header-cart__fade-enter-active {
  animation: bsc-header-cart__fade 0.3s;
}

.bsc-header-cart__fade-leave-active {
  animation: bsc-header-cart__fade 0.3s reverse;
}
</style>
