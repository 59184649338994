import { ref, onMounted, onUnmounted, Ref } from 'vue'
import { throttle } from '@shein/common-function'

// 用于判断指定 dom 是否被用户可见
export const useIsVisible = (targetDom: Ref<HTMLElement | null>) => {
  const isVisible = ref(false)

  let observer: IntersectionObserver | null = null
  const intersectionObserver = () => {
    if (observer) return
    if (!targetDom.value) return

    // 观察器选项
    const options: IntersectionObserverInit = {
      threshold: 0.1, // 阈值，表示目标元素的可见比例
    }

    observer = new IntersectionObserver(entries => {
      isVisible.value = entries[0].isIntersecting
    }, options)

    observer.observe(targetDom.value)
  }
  const unobserve = () => {
    if (observer && targetDom.value) {
      observer.unobserve(targetDom.value)
      observer = null
    }
  }

  let eventListener: any = null
  const addEventListener = () => {
    if (eventListener) return
    if (!targetDom.value) return

    eventListener = throttle({
      func: () => {
        if (!targetDom.value) {
          isVisible.value = false
          return
        }
        const { x, y, width, height } = targetDom.value.getBoundingClientRect()
        if (y < 0) {
          isVisible.value = false
          return
        }
        const ponitTarget = document.elementFromPoint(
          x + width / 2,
          y + height / 2,
        )
        if (!ponitTarget) {
          isVisible.value = false
          return
        }

        const res = hasParentElement(ponitTarget, targetDom.value)
        if (res) {
          isVisible.value = true
        } else {
          isVisible.value = false
        }
      },
      wait: 500,
      options: {
        leading: false,
      },
    })
    window.addEventListener('scroll', eventListener)
  }
  const removeEventListener = () => {
    if (eventListener) {
      window.removeEventListener('scroll', eventListener)
      eventListener = null
    }
  }

  onMounted(() => {
    if (targetDom.value) {
      intersectionObserver()
      addEventListener()
    }
  })

  onUnmounted(() => {
    unobserve()
    removeEventListener()
  })

  return {
    isVisible,
  }
}

function hasParentElement(childElement, parentElement) {
  // 如果子元素不存在，直接返回 false
  if (!childElement) {
    return false
  }

  // 如果当前元素与指定的父元素相同，则找到匹配，返回 true
  if (childElement === parentElement) {
    return true
  }

  // 递归检查父元素
  return hasParentElement(childElement.parentElement, parentElement)
}
