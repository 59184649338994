<template>
  <a
    v-tap="{
      id: 'click_news.comp_message-bar',
      data: { unread_amount: existOrderOrNewsUnRead },
    }"
    v-expose="{
      id: 'expose_news.comp_message-bar',
      data: { if_unread: showMessagePoint ? 1 : 0 },
    }"
    :aria-label="ariaLabel"
    class="message-bar"
    tabindex="0"
    role="link"
    @click="handelClickMessage"
  >
    <svg
      style="width: auto; height: 1em; vertical-align: middle; display: inline-block; line-height: 0; font-size: 24px;"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 4.5V19.5H2V4.5H22ZM20.5 6H3.5V6.43195L12 12.098L20.5 6.43196V6ZM20.5 8.23474V18H3.5V8.23473L12 13.9014L20.5 8.23474Z"
        fill="var(--shein-common-header-icon-color, #000)"
      />
    </svg>
    <em
      v-if="showMessagePoint"
      :class="[
        !existOrderOrNewsUnRead && existActivityOrPromoUnreadNum
          ? 'message-bar__dot'
          : 'message-bar__txt-dot',
      ]"
      :style="{ border: '1px solid var(--shein-common-header-badge-border-color)' }"
    >
      {{
        existOrderOrNewsUnRead
          ? existOrderOrNewsUnRead > 99
            ? '99+'
            : existOrderOrNewsUnRead
          : ''
      }}
    </em>
  </a>
</template>

<script name="BMessageBar" setup lang="ts">
import { ref, computed, onMounted } from 'vue'
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_MessageBar, DS_MessageBar } from '../../types'

const appConfigs = useAppConfigs()
const ariaLabel = appConfigs.$language.Message || 'Message'

const props = withDefaults(defineProps<MessageBarProps>(), {
  messageViewMode: '',
})

// 埋点源：
import { useAnalysis } from '../../common/analysisSource'
const { vTap, vExpose } = useAnalysis(props.analysisSource)
// 埋点源：END

interface MessageBarProps {
  messageViewMode?: string

  // 数据源
  fsData?: DS_MessageBar.FS_DATA
  dataSource?: DS_MessageBar.DataSource
  // 埋点源
  analysisSource?: AS_MessageBar.AnalysisSource
}

const apis = getSource({ appConfigs, dataSource: props.dataSource })
// 数据源：END

const emits = defineEmits(['clickMessage'])

const orderOrNewsUnRead = ref(0)
const newsUnreadNum = ref(0)
const orderUnRead = ref(0)
const activityUnreadNum = ref(0)
const promoUnreadNum = ref(0)
const messageInfo = ref({})

const isOldView = computed(
  () => props.messageViewMode === 'Old' || !props.messageViewMode,
)
const existOrderOrNewsUnRead = computed(() =>
  isOldView.value ? orderOrNewsUnRead.value : orderUnRead.value,
)
const existActivityOrPromoUnreadNum = computed(() =>
  isOldView.value
    ? activityUnreadNum.value + promoUnreadNum.value
    : activityUnreadNum.value + promoUnreadNum.value + newsUnreadNum.value,
)
const showMessagePoint = computed(() => {
  // 登录状态： 展示order或者news的数字 如果这两个数字都没有的情况下 活动促销有值的话 显示红点
  // 未登录状态： 活动促销有值的话 显示红点
  const isLogin = appConfigs.$getters?.isLogin
  if (isLogin) {
    return !!(
      activityUnreadNum.value +
      promoUnreadNum.value +
      orderOrNewsUnRead.value
    )
  }
  return !!(activityUnreadNum.value + promoUnreadNum.value)
})

const transformDataEvt = result => {
  const info = result.info || {}
  const orderUnreadCount = Number((info.order && info.order.num) || 0)
  const newsUnreadCount = Number((info.news && info.news.num) || 0)
  const activityUnreadCount = Number((info.activity && info.activity.num) || 0)
  const promoUnreadCount = Number((info.promo && info.promo.num) || 0)
  // promo activity是否展示开关
  const activityShow = !!Number((info.activity && info.activity.is_show) || 0)
  const promoShow = !!Number((info.promo && info.promo.is_show) || 0)

  orderOrNewsUnRead.value = orderUnreadCount + newsUnreadCount
  newsUnreadNum.value = newsUnreadCount
  orderUnRead.value = orderUnreadCount
  activityUnreadNum.value = activityShow ? activityUnreadCount : 0
  promoUnreadNum.value = promoShow ? promoUnreadCount : 0
}

const handelClickMessage = () => {
  emits('clickMessage')
  const langPath = appConfigs.$envs?.langPath
  appConfigs.$router.push({ path: `${langPath}/user/message` })
}

const fetchUnreadMsg = async () => {
  const res = await apis.fetchUnreadMsg()
  messageInfo.value = res.info || {}
  transformDataEvt(res)
}

onMounted(() => {
  fetchUnreadMsg()
})

defineExpose({
  fetchUnreadMsg,
  newsUnreadNum,
  orderUnReadNum: orderUnRead,
  activityUnreadNum,
  promoUnreadNum,
  messageInfo,
})

</script>

<style lang="less">
.message-bar {
  position: relative;
  display: inline-block;
  // 右上角红点
  &__dot {
    margin-left: auto;
    margin-right: -3px;
    background: #fe3b30;
    border-radius: 50%;
    content: '';
    height: 6px;
    min-width: auto;
    padding: 0;
    top: -3px;
    width: 6px;
    position: absolute;
    border: 1px solid #fff;
  }
  // 右上角信息
  &__txt-dot {
    position: absolute;
    top: -7.5px;
    margin-left: 12px;
    min-width: 15px;
    height: 15px;
    line-height: 6.75px;
    padding: 3.75px;
    box-sizing: border-box;
    font-size: 10px;
    display: block;
    border-radius: 15px;
    text-align: center;
    background: #fe3b30;
    color: #fff;
    border: 1px solid #fff;
  }
}
</style>
