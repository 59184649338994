import { ref, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import { getScrollTop } from './utils.js'
import { throttle } from '@shein/common-function'

const headerFixedInstance = ref()
const scrollTop = ref(0)
const isSticky = ref(false)
class StickyHeader {
  constructor({ el, headerStatus }) {
    this._el = el
    this._width = headerStatus.wrapStyle?.width || '100vw'
    this.originalPosition = 'relative'
    this.placeholderDom = null // 当fixed时，当前元素用于判断位置的dom
    this.stickyPlaceholderDom = null // 用于占位的dom,当元素fixed时，进行占位
    this._isSticky = false
    this.count = 0 // 防止无限循环
    this.lastHeight = 0 // 上一次的高度(旋转屏幕时，需要重新计算高度)
    window.addEventListener('orientationchange', () => {
      this.handleOrientationChange()
    })
    this.init()
  }

  init() {
    const height = this._el.getBoundingClientRect().height
    this.lastHeight = height
    const updateStyle = () => {
      this.placeholderDom.style.cssText = `height: ${height}px; position: absolute; top: 0; width: ${this._width}; z-index: -1; `
      this.stickyPlaceholderDom.style.cssText = `height: ${height}px; display: ${
        this._isSticky ? 'block' : 'none'
      };`
    }
    if (this.warpDom) {
      return updateStyle()
    }

    const fragment = document.createDocumentFragment()
    this.warpDom = document.createElement('div')
    this.placeholderDom = document.createElement('div')
    this.stickyPlaceholderDom = document.createElement('div')
    this.warpDom.style.cssText = 'position: relative;'
    updateStyle()
    this.warpDom.appendChild(this.placeholderDom)
    this.warpDom.appendChild(this.stickyPlaceholderDom)
    fragment.appendChild(this.warpDom)
    this._el.parentNode.insertBefore(fragment, this._el)
  }

  handleOrientationChange() {
    window.requestAnimationFrame(() => {
      const curHeight = this._el.getBoundingClientRect().height
      if (curHeight === this.lastHeight && this.count <= 60) {
        // 高度还没有完成更新,递归执行 requestAnimationFrame
        this.count++
        this.handleOrientationChange()
      } else {
        // 高度更新完成
        this.lastHeight = curHeight
        this.count = 0
        this.init()
      }
    })
  }

  /**
   * @description: 监听滚动事件,判断是否fixed
   * @param {Boolean} forceFixed 强制fixed
   * */
  invokeFixed(forceFixed) {
    const callShow = () => {
      this._isSticky = true
      isSticky.value = true
      this._el.style.cssText =
        `position: fixed; top: 0; width: ${this._width}; z-index: 11; transform: translate3d(0, 0, 11px);`
      this.stickyPlaceholderDom.style.display = 'block'
    }

    const callHide = () => {
      this._isSticky = false
      isSticky.value = false
      this._el.style.cssText = `position: ${this.originalPosition}; `
      this.stickyPlaceholderDom.style.display = 'none'
    }

    if (typeof forceFixed === 'boolean') {
      // 强制fixed
      forceFixed ? callShow() : callHide()
      return this._isSticky
    }

    const top = this.placeholderDom.getBoundingClientRect().top
    if (top <= 0 && !this._isSticky) {
      callShow()
    } else if (top > 0 && this._isSticky) {
      callHide()
    }
    return this._isSticky
  }
}

export default function useSticky(headerOptions, headerStatus) {
  // 监听页面滚动
  const handleScroll = () => {
    if (headerOptions.closeScrollEvent?.value) return
    let scrollY = getScrollTop()
    scrollTop.value = scrollY
    headerFixedInstance.value?.invokeFixed()
  }

  const handleScrollByThrottle = throttle({
    func: function () {
      handleScroll()
    },
    wait: headerOptions.scrollThreshold || 80,
    options: {
      leading: true,
      trailing: true,
    },
  })

  const initEvent = () => {
    window.addEventListener('scroll', handleScrollByThrottle, {
      passive: false,
    })
  }

  const removeEvent = () => {
    window.removeEventListener('scroll', handleScrollByThrottle, {
      passive: false,
    })
  }

  onMounted(() => {
    const vm = getCurrentInstance()
    const el = vm.proxy.$el
    headerFixedInstance.value = new StickyHeader({ el, headerStatus })
    initEvent()
  })

  onBeforeUnmount(() => {
    removeEvent()
  })
  return { isSticky, scrollTop, headerFixedInstance }
}
