import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BCommonHeader } from './common-header.vue'
export {
  injectCommonHeaderContext,
  injectCommonHeaderEventProxy,
} from './common-header-context.ts'
import type { DS_CommonHeader } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getCommonHeaderFsDataRunner(
  dataSource?: DS_CommonHeader.DataSource,
) {
  return getFsDataRunner(dataSource, useAppConfigs())
}
