import type { DS_CommonHeader } from '../../../types'

// TODO write your apis
export const api1: DS_CommonHeader.APIS_THIS['api1'] = async function (
  x,
  y,
  z,
) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/*',
    params: { x, y, z },
  })
  return result.data
}
