<template>
  <transition
    :name="transitionName"
    @after-enter="afterEnter"
  >
    <template v-if="show">
      <span
        v-if="type === 'freeshipping' && category === 'activity'"
        key="freeshipping"
        ref="refBCartTip"
        class="bsc-cart-tip bsc-cart-tip--freeshipping"
        :class="classname"
        :style="style"
        @click="onClick"
      >
        <FreeshippingSvg />
        <span
          class="bsc-cart-tip__text"
          v-html="text"
        ></span>
      </span>
      <span
        v-if="type === 'freeshipping' && category === 'coupon'"
        key="free-coupon"
        ref="refBCartTip"
        class="bsc-cart-tip bsc-cart-tip--free-coupon"
        :class="classname"
        :style="style"
        @click="onClick"
      >
        <span class="mask-box">
          <FreeshippingSvg />
          <span class="line">&nbsp;</span>
          <span
            class="bsc-cart-tip__text"
            v-html="text"
          ></span>
        </span>
      </span>
      <span
        v-else-if="type === 'save'"
        key="save"
        ref="refBCartTip"
        class="bsc-cart-tip bsc-cart-tip--save"
        :class="classname"
        :style="bgAllStyle"
        @click="onClick"
      >
        <span
          class="bsc-cart-tip__text"
          :style="textStyle"
          v-html="text"
        ></span>
      </span>
      <span
        v-else-if="type === 'gift'"
        key="gift"
        ref="refBCartTip"
        class="bsc-cart-tip bsc-cart-tip--gift"
        :class="classname"
        :style="bgAllStyle"
        @click="onClick"
      >
        <template v-if="!textStyle">
          <GiftSvg />
        </template>
        <span
          class="bsc-cart-tip__text"
          :style="textStyle"
          v-html="text"
        ></span>
      </span>
    </template>
  </transition>
</template>

<script name="BCartTip" setup lang="ts">
import { ref, computed, nextTick } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { Placement, TagTip } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { C_HeaderCart } from '../../../../types'
import { computePosition, getSide } from '../../../../common/popup/computePosition'
import FreeshippingSvg from '../../svgs/freeshipping.vue'
import GiftSvg from '../../svgs/gift.vue'

const { $envs } = useAppConfigs()

const emit = defineEmits(['click', 'expose'])

const style = ref({})
const bgStyle = ref({})
const textStyle = ref({})

const bgAllStyle = computed(() => ({
  ...style.value,
  ...bgStyle.value,
}))
const classname = computed(() => {
  return [
    `bsc-cart-tip__${_placement.value}`,
    {
      'bsc-cart-tip__css-right': $envs.cssRight,
    },
  ]
})

const show = ref(false)
const transitionName = ref('')
const type = ref<TagTip.Step['type']>('')
const category = ref<TagTip.Step['category']>(null)
const text = ref('')

const _placement = ref<Placement>('bottom-end')

let callback: TagTip.Config['callback']

const open = (step: TagTip.Step, config: C_HeaderCart.TipOpenConfig) => {
  bgStyle.value = config.bgStyle || {}
  textStyle.value = config.textStyle || {}

  nextTick(() => {
    callback = config.callback

    _placement.value = config.placement || _placement.value

    type.value = step.type
    category.value = step.category
    text.value = step.tips as string
    transitionName.value = `bsc-cart-tip__jump-${getSide(_placement.value)}`


    show.value = true

    nextTick(() => {
      setStyle(config)
    })

    emit('expose')
  })
}

const refBCartTip = ref<HTMLElement>()
const setStyle = ({ reference, placement, offsetY }: C_HeaderCart.TipOpenConfig) => {
  if (!refBCartTip.value || !reference) return
  const place = placement || _placement.value
  const position = computePosition(reference, refBCartTip.value, { placement: place, offsetY: offsetY ?? 10, isRTL: $envs.cssRight })
  style.value = {
    top: `${position.y}px`,
    left: `${position.x}px`,
  }
}

const afterEnter = () => {
  transitionName.value = ''
  show.value = false

  callback?.()
  callback = undefined
}

const onClick = () => {
  emit('click')
}

const clear = () => {
  afterEnter()
}

defineExpose({
  open,
  clear,
})
</script>

<style lang="less">
// @translateY: calc(100% + 15px);
@translateY: 0px;
@space: 10px;
@jump: 10px;

@keyframes bsc-cart-tip__jump-top {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
    transform: translateY(calc(@translateY - @space - @jump));
  }
  31.75% {
    opacity: 1;
    transform: translateY(calc(@translateY - @space));
  }
  51.5% {
    transform: translateY(calc(@translateY - @space - @jump));
  }
  71.25% {
    transform: translateY(calc(@translateY - @space));
  }
  91% {
    opacity: 1;
    transform: translateY(calc(@translateY - @space - @jump));
  }
  100% {
    opacity: 0;
    transform: translateY(calc(@translateY));
  }
}

@keyframes bsc-cart-tip__jump-bottom {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 0;
    transform: translateY(calc(@translateY + @space + @jump));
  }
  31.75% {
    opacity: 1;
    transform: translateY(calc(@translateY + @space));
  }
  51.5% {
    transform: translateY(calc(@translateY + @space + @jump));
  }
  71.25% {
    transform: translateY(calc(@translateY + @space));
  }
  91% {
    opacity: 1;
    transform: translateY(calc(@translateY + @space + @jump));
  }
  100% {
    opacity: 0;
    transform: translateY(calc(@translateY));
  }
}

.bsc-cart-tip__jump-top-enter-active {
  animation: bsc-cart-tip__jump-top 2.2s;
}

.bsc-cart-tip__jump-bottom-enter-active {
  animation: bsc-cart-tip__jump-bottom 2.2s;
}

.bsc-cart-tip {
  position: fixed;
  opacity: 0;
  display: inline-flex;
  align-items: center;
  // margin-right: -13px;
  padding: 8px;
  max-width: 176px;
  width: max-content;
  color: #fff;
  border-radius: 2px;
  z-index: 99;

  &__text {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: var(--tag-tip-text-color, #fff);
  }

  &--save,
  &--gift {
    background: var(--tag-tip-bg-color, #fa6338);

    &::before {
      background: var(--tag-tip-bg-color, #fa6338);
    }
  }

  &--freeshipping {
    background: #1b8157;

    &::before {
      background: #1b8157;
    }
  }

  &.bsc-cart-tip--free-coupon {
    padding: 0;

    &::before {
      background: #1b8157;
    }

    .mask-box {
      display: inline-flex;
      align-items: center;
      padding: 8px;
      padding-right: 6px;
      width: 100%;
      background: #1b8157;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/08/01/e3/1722494176634a5781f062bd413c71c7b767497a74.png);
      background-repeat: no-repeat;
      background-position: right;
      mask-image: radial-gradient(
        circle at 4px 4px,
        transparent 4px,
        #fff 4px
      );
      mask-position: 27.5px -4px;
    }

    &.bsc-cart-tip__css-right {
      .mask-box {
        mask-position: -35.5px -4px;
      }
    }

    svg {
      margin-right: 7px;
      width: 16px;
      height: 16px;
    }

    .line {
      display: inline-block;
      margin-right: 6px;
      width: 0;
      line-height: 24px;
      border-left: 1px dashed rgba(#fff, 0.3);
    }

    .bsc-cart-tip__text {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
  }

  &__top::before {
    bottom: 0;
    left: 50%;
    margin-left: -6px;
    transform-origin: bottom left;
    transform: rotateZ(45deg);
  }
  &__top-start::before {
    bottom: 0;
    left: 6px;
    transform-origin: bottom left;
    transform: rotateZ(45deg);
  }
  &__top-end::before {
    bottom: 0;
    right: 6px;
    transform-origin: bottom right;
    transform: rotateZ(-45deg);
  }

  &__bottom::before {
    top: 0;
    left: 50%;
    margin-left: -6px;
    transform-origin: top left;
    transform: rotateZ(-45deg);
  }
  &__bottom-start::before {
    top: 0;
    left: 6px;
    transform-origin: top left;
    transform: rotateZ(-45deg);
  }
  &__bottom-end::before {
    top: 0;
    right: 6px;
    transform-origin: top right;
    transform: rotateZ(45deg);
  }

  svg {
    flex-shrink: 0;
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
}
</style>
