import Vue from 'vue'
import AppCartTip from './index.vue'

function createCartTip() {
  // @ts-ignore
  const cartTip = new Vue(AppCartTip)
  const div = document.createElement('div')
  document.body.appendChild(div)
  cartTip.$mount(div)
  return cartTip
}

export { createCartTip }
