import * as standard from './sources/standard'
import {
  organizeDataSource,
  createFsDataRunnerBySource,
} from '@shein-aidc/utils-data-source-toolkit'
import type { Libs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_CommonHeader } from '../../types'

export function getSource(
  dataSource: DS_CommonHeader.DataSource = {},
  appConfigs: Libs.AppConfigs,
): DS_CommonHeader.APIS {
  return organizeDataSource<DS_CommonHeader.APIS, DS_CommonHeader.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(
  dataSource: DS_CommonHeader.DataSource = {},
  appConfigs: Libs.AppConfigs,
) {
  const { api1 } = getSource(dataSource, appConfigs)
  return createFsDataRunnerBySource<
    DS_CommonHeader.FS_APIS,
    DS_CommonHeader.FS_DATA
  >({
    api1,
  })
}
