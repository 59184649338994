<script lang="jsx">
export default {
  name: 'BHeaderCartSvg',
  functional: true,
  render() {
    return <svg
      class="bsc-header-cart__svg"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="var(--shein-common-header-icon-color, #000)"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.796 6.04999H21.0108L19.6582 16.7695H6.63182L5.27917 6.04999H5.28453L5.15442 5H3V3.5H6.48003L6.796 6.04999ZM6.98187 7.54999L7.03628 7.98912L7.95444 15.2695H18.3356L19.3096 7.54999H6.98187Z"
      />
      <path
        d="M9.14995 20.25C9.14995 20.9404 8.59032 21.5 7.89999 21.5C7.20965 21.5 6.65002 20.9404 6.65002 20.25C6.65002 19.5596 7.20965 19 7.89999 19C8.59032 19 9.14995 19.5596 9.14995 20.25Z"
      />
      <path
        d="M19.6999 20.25C19.6999 20.9404 19.1402 21.5 18.4499 21.5C17.7596 21.5 17.2 20.9404 17.2 20.25C17.2 19.5596 17.7596 19 18.4499 19C19.1402 19 19.6999 19.5596 19.6999 20.25Z"
      />
    </svg>
  },
}
</script>
