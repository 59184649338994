import standard from './sources/standard'
import pcStandard from './sources/pcStandard'
import { addSubscribe } from '@shein-aidc/bs-sdk-libs-da-event-center'
import type { AS_HeaderCart } from '../../types'

export function useAnalysis(input?: AS_HeaderCart.AnalysisSource): AS_HeaderCart.AnalysisInstance {
  return addSubscribe(standard, input)
}

export function usePCAnalysis(input?: AS_HeaderCart.PCAnalysisSource): AS_HeaderCart.PCAnalysisInstance {
  return addSubscribe(pcStandard, input)
}
